body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

header {
  background-color: cornflowerblue;
  color: aliceblue;
}

header h1 {
  margin: 0;
  font-size: 4em;
  padding: 10px 0 10px 10px;
}

header a {
  color: #fff;
}

header a:hover {
  text-decoration: none;
  color: darkslategrey;
  font-weight: bold;
}

footer {
  font-size: small;
  min-height: 100px;
  color: dimgray;
}

footer a {
  text-decoration: none;
  color: darkgray;
}

.ml-input-horizontal {
  padding-top: 3px;
}

footer .info-issues {
  color: cornflowerblue;
}

.api-label {
  color: darkgray;
}

#api-url-select {
  font-size: small;
  background-color: darkgrey;
}
