#fortune {
    font-size: large;
}

p#fortune:hover {
    background-color: yellowgreen;
}

.source {
    font-size: smaller;
}

span:hover.link {
    color: cornflowerblue;
    font-weight: bolder;
}
